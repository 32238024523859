@import '../../../../scss/theme-bootstrap';

.gnav-section-group {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 48px;
  padding-#{$ldirection}: $gnav-mobile-hpad--inner;
  // Mobile specific expanded state with parent group expanded
  .gnav-mobile-group-active & {
    @include breakpoint($landscape-down) {
      padding-#{$ldirection}: 0;
    }
  }
  @include breakpoint($landscape-up) {
    @include container-max-width-full;
    flex-direction: row;
    height: auto;
    padding: 0 $container-pad-landscape 0 $container-pad-landscape;
  }
  .pc-trigger-hidden {
    @include breakpoint($landscape-up) {
      opacity: 0;
      text-shadow: none;
      display: none;
      &:hover,
      &:visited {
        box-shadow: none;
      }
    }
  }
  &__section {
    // Mobile specific expanded state with parent group expanded
    &.mobile-expanded--true {
      @include breakpoint($landscape-down) {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}
